import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import SEO from "../components/seo"
import BlogLayout from "../components/blog-layout"

import "./index.css"

const PostsPage = () => {
    const pageQuery = useStaticQuery(graphql`query {
        posts: allMarkdownRemark(
            filter: {fields: {
                collection: {regex: "/blog/"}
                attic: {eq: "no"}
            }}
            sort: { fields: [frontmatter___order], order: ASC }
            limit: 1000
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        date(formatString: "YYYY-MM-DD HH")
                        description
                    }
                    fields {
                        collection
                        slug
                    }
                }
            }
        }
    }`);

    return (
        <BlogLayout innerStyle="index">
            <SEO title="Posts" />
            <main className="main-contents">
                <h1>Posts</h1>
                {pageQuery.posts.edges.map(({ node }) => {
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                    <article key={node.fields.slug} style={{
                        borderBottom: "dotted 1px",
                        position: "relative",
                        padding: "1rem 0"
                    }}>
                        <h3 style={{
                            margin: 0
                        }}>
                            <Link style={{ boxShadow: `none` }} to={`/posts${node.fields.slug}`}>
                            {title}
                            </Link>
                        </h3>
                        <small>{node.frontmatter.date}時頃</small>
                    </article>
                    )
                })}
            </main>
        </BlogLayout>
    );
}

export default PostsPage
