/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./layout.css"
import "./blog.css"

const BlogLayout = ({ children }) => {
return (<>
        <div className="main-container blog">{children}</div>
        <nav className="bottom-nav">
            <span>© {new Date().getFullYear()}, Yanase Takuma</span>
            <ul className="index-nav">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about/">About</Link></li>
                <li><Link to="/posts/">Posts</Link></li>
            </ul>
        </nav>
    </>);
}

BlogLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default BlogLayout
